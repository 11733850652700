import Sidebar from "../Sidebar";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export default (props) => {
  return (
    <Container>
      <Sidebar />
      <Content>
        {props.children}
      </Content>
    </Container>
  );
};
