import styled from "styled-components";
import * as colors from "../colors";

const Row = styled.div`
  display: flex;
  flex-direction: row;
  color: ${colors.defaultText};
  border-bottom: 1px solid ${() => colors.primary};
`;

export default Row;
