import { Navigate, Routes, Route } from "react-router-dom";
import ChannelListScreen from "./ChannelListScreen";
import LogListScreen from "./LogListScreen";
import NotFoundScreen from "./NotFoundScreen";
import DefaultLayout from "../components/layouts/DefaultLayout";

export default () => {
  return (
    <DefaultLayout>
      <Routes>
        <Route path="*" element={<Navigate to="/channels" />} />
        <Route path="/channels" element={<ChannelListScreen />} />
        <Route path="/channels/:channelId/logs" element={<LogListScreen />} />
        <Route element={<NotFoundScreen />} />
      </Routes>
    </DefaultLayout>
  );
};
