import userApi from "../apis/userApi";
import { EventEmitter } from "events";
import sessionService from "./sessionService";

class UserService extends EventEmitter {
  async init() {
    try {
      await userApi.authorization();
      this.emit("authentication");
    } catch (err) {
      console.error(err);
      if (err.response.data.message === "token_invalid") await this.logout();
    } finally {
      this.emit("ready");
    }
  }

  async login(username: string, password: string) {
    const { token } = await userApi.login(username, password);

    this.setLoggedIn(token);

    this.emit("authentication");

    return { token };
  }

  setLoggedIn(token: string) {
    sessionService.setToken(token);
  }

  async logout() {
    this.setLoggedOut();
  }

  setLoggedOut() {
    sessionService.removeToken();
    this.emit("logout");
  }
}

export default new UserService();
