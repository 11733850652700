import userService from "../services/userService";
import sessionService from "../services/sessionService";
import { useState, useEffect } from "react";

export default () => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(!!sessionService.getToken());

  const onAuthentication = () => {
    setIsAuthenticated(true);
  };

  const onLogout = () => {
    setIsAuthenticated(false);
  };

  useEffect(() => {
    userService.addListener("authentication", onAuthentication);
    userService.addListener("logout", onLogout);
    return () => {
      userService.removeListener("authentication", onAuthentication);
      userService.removeListener("logout", onLogout);
    };
  }, []);

  return isAuthenticated;
};
