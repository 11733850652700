import { EventEmitter } from "events";
import userService from "./userService";

class AppService extends EventEmitter {
  public ready = false;

  async init() {
    await userService.init()
    this.ready = true;
    this.emit("readyChanged", this.ready);
  }
}

export default new AppService();
