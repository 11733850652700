import * as config from "../config";
import { BaseApi } from "@etops/logger-api-client";
import sessionService from "../services/sessionService";

const token = sessionService.getToken();
export default new BaseApi({
  token,
  version: config.apiVersion,
  baseUrl: config.apiBaseUrl
});
