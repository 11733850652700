import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import moment from "moment-timezone";
import useChannels from "../hooks/useChannels";
import ContentLayout from "../components/layouts/ContentLayout";
import Table from "../components/Table";
import Header from "../components/Header";
import Row from "../components/Row";
import Column from "../components/Column";
import Body from "../components/Body";
import Title from "../components/Title";
import LoadingScreen from "./LoadingScreen";

const ChannelLink = styled(Link)`
  text-decoration: none;
`;

const NameColumn = styled(Column)`
  flex: 1;
`;

const CreatedDateColumn = styled(Column)`
  flex: 1;
  max-width: 130px;
`;

const ParentColumn = styled(Column)`
  flex: 1;
  max-width: 100px;
`;

export default () => {
  let [loadingChannels, channels] = useChannels();

  if (loadingChannels) return <LoadingScreen />;

  return (
    <ContentLayout>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <Title style={{ flex: 1 }}>Channels</Title>
      </div>

      <Table>
        <Header>
          <Row>
            <NameColumn>
              Name
            </NameColumn>
            <CreatedDateColumn>
              Created
            </CreatedDateColumn>
            <ParentColumn>
              Parent
            </ParentColumn>
          </Row>
        </Header>

        <Body>
          {channels.map(channel => (
            <ChannelLink key={channel._id} to={`/channels/${channel._id}/logs`}>
              <Row>
                <NameColumn>
                  {channel.name || ""}
                </NameColumn>
                <CreatedDateColumn>
                  {channel.created ? moment(channel.created).format("YYYY.MM.DD HH:mm:ss") : ""}
                </CreatedDateColumn>
                <ParentColumn>
                  {channels.find(x => x._id === channel.parentChannelId) ?
                    channels.find(x => x._id === channel.parentChannelId).name :
                    ""
                  }
                </ParentColumn>
              </Row>
            </ChannelLink>
          ))}
          <ChannelLink key={"uncategorized"} to={`/channels/uncategorized/logs`}>
            <Row>
              <NameColumn>
                Uncategorized
              </NameColumn>
            </Row>
          </ChannelLink>
        </Body>
      </Table>
    </ContentLayout>
  );
};
