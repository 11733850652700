import styled from "styled-components";

const Title = styled.div`
  font-weight: 500;
  color: #000;
  font-size: 14px;
  margin-bottom: 20px;
  user-select: none;
`;

export default Title;
