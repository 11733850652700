import React from "react";
import * as colors from "../colors";
import styled, { keyframes, css } from "styled-components";
import { ReactComponent as Logo } from "../images/logo.svg";

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const rotateAnimation = css`
  animation: ${rotate} 1s ease-in-out infinite;
`;

const rotateOnceStyle = css`
  color: ${colors.primary};
  transform: rotate(360deg);
`;

interface ContainerProps {
  rotate?: "true";
  rotateOnce?: "true";
  size?: string;
}

const Container = styled.div<ContainerProps>`
  user-select: none;
  transform: rotate(0deg);
  transition: color 2s ease, transform 1s ease;
  height: ${props => props.size};
  width: ${props => props.size};

  &:hover {
    ${rotateOnceStyle}
  }

  ${props => props.rotateOnce ? rotateOnceStyle : ""}
  ${props => props.rotate ? rotateAnimation : ""}
`;

interface Props {
  rotate?: "true";
  rotateOnce?: "true";
  size?: string;
  className?: string;
}

export default (props: Props) => {
  const { rotate, rotateOnce, size, className } = props;
  return (
    <Container
      rotate={rotate}
      rotateOnce={rotateOnce}
      size={size}
      className={className}
    >
      <Logo />
    </Container>
  );
}
