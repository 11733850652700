import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import styled, { css } from "styled-components";
import * as colors from "../colors";
import Flex from "./Flex";
import EtopsLogo from "./EtopsLogo";
import { IconType } from "react-icons";
import { BsGrid } from "react-icons/bs";

const Container = styled.div`
  display: flex;
  flex-direction: column;

  background-color: #f9f9f9;
  width: 200px;
  min-width: 200px;
  height: 100%;
  padding: 0 10px;
  overflow-x: hidden;
  overflow-y: auto;
  border-right: 1px solid #e0e3e8;
`;

const LogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 20px;
`;

const Logo = styled(EtopsLogo)`
  margin-right: 5px;
`;

const LogoText = styled.div`
  font-size: 20px;
  user-select: none;
`;

const Items = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  user-select: none;
`;

const ItemContainer = styled.a<{ active?: "true"; }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 10px;
  border-radius: 4px;
  text-decoration: none;
  color: ${colors.defaultText};
  cursor: pointer;

  ${props => props.active === "true" ? css` color: ${colors.primary}; ` : ""}

  &:hover {
    color: ${colors.primary};
    background-color: ${colors.primary}20;
  }
`;

const ItemIcon = styled.div`
  margin-right: 20px;
  line-height: 18px;
  height: 18px;
`;

const ItemText = styled.div`
  flex: 1;
  font-size: 14px;
  margin-top: 4px;
  margin-bottom: 4px;
`;

interface Item {
  text: string;
  Icon: IconType;
  url: string;
  handler?: () => any;
}

const items: Item[] = [{
  url: "/channels",
  text: "Channels",
  Icon: BsGrid
}];

export default () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [activeItemIndex, setActiveItemIndex] = useState(-1);

  const onItemClick = (item: Item, ItemIndex: number) => (e: React.MouseEvent) => {
    e.preventDefault();
    setActiveItemIndex(ItemIndex);
    navigate(item.url);
  };

  useEffect(() => {
    const ItemIndex = items.findIndex(x => location.pathname.startsWith(x.url));
    if (ItemIndex === -1) return;

    setActiveItemIndex(ItemIndex);
  }, [location.pathname]);

  return (
    <Container>
      <LogoContainer>
        <Logo size="50px" rotateOnce="true" />
        <LogoText>Logger</LogoText>
      </LogoContainer>
      <Items>
        {items.map((item, index) => (
          <ItemContainer
            key={item.url}
            href={item.url}
            active={index === activeItemIndex ? "true" : undefined}
            onClick={onItemClick(item, index)}
          >
            <ItemIcon>
              <item.Icon size="18px" />
            </ItemIcon>
            <ItemText>{item.text}</ItemText>
          </ItemContainer>
        ))}
      </Items>
      <Flex />
    </Container>
  );
};
