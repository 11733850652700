import React, { useState } from "react";
import Button from "../components/Button";
import userService from "../services/userService";
import CenterLayout from "../components/layouts/CenterLayout";
import styled from "styled-components";
import TextInput from "../components/TextInput";
import BasicTextInput from "../components/BasicTextInput";

const Title = styled.h2`
  margin: 12px 0;
`;

const PasswordInput = styled(BasicTextInput).attrs({ type: "password" })``;

const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 4px 0;
`;

const CenterContainer = styled.div`
  flex: 1;
  width: 100%;
  max-width: 260px;
  max-height: 180px;
  background-color: #fff;
  padding: 20px 30px;
  box-shadow: 0 5px 15px 0px rgba(0,0,0,0.2);
  display: flex;
  flex-direction: column;
`;

const Submit = styled(Button)``;

const ErrorMessage = styled.div`
  color: red;
  font-size: 13px;
`;

export default () => {
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [fetching, setFetching] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const login = async () => {
    if (fetching) return;
    setFetching(true);

    try {
      await userService.login(username, password);
    } catch (err) {
      console.error(err);
      if (err.response.data.message === "invalid_credentials") {
        setErrorMessage("Invalid Credentials.");
      }
    } finally {
      setFetching(false);
    }
  }

  const onEnter = async (e: React.KeyboardEvent) => {
    if (e.key !== "Enter") return;
    await login();
  }

  const onLoginClick = async () => await login();

  const onUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserName(e.currentTarget.value);
  }

  const onPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.currentTarget.value);
  }

  return (
    <CenterLayout>
      <CenterContainer>
        <Title>Logger</Title>
        <Row>
          <TextInput value={username} placeholder="Username" id="username" onChange={onUsernameChange} onKeyDown={onEnter} />
        </Row>
        <Row>
          <PasswordInput value={password} placeholder="Password" id="password" onChange={onPasswordChange} onKeyDown={onEnter} />
        </Row>
        {errorMessage && <Row>
          <ErrorMessage>{errorMessage}</ErrorMessage>
        </Row>}
        <Row>
          <Submit onClick={onLoginClick} onKeyDown={onEnter}>Login</Submit>
        </Row>
      </CenterContainer>
    </CenterLayout>
  );
}
