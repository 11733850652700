import { EventEmitter } from "events";

class SessionService extends EventEmitter {
  setToken(token: string) {
    return localStorage.setItem("_t", token);
  }

  getToken() {
    return localStorage.getItem("_t");
  }

  removeToken() {
    localStorage.removeItem("_t");
  }
}

export default new SessionService();
