import React from "react";
import Scroll from "./Scroll";

interface Props {
  threshold?: number;
  onBump: () => Promise<void>;
  children: React.ReactNode;
}

interface State {
  triggered: boolean;
}

export default class Pagination extends React.Component<Props, State> {
  state: State = {
    triggered: false
  };

  async componentDidMount() {
    await this.triggerBump();
  }

  render() {
    const {
      children
    } = this.props;

    return (
      <Scroll onScroll={this.onScroll}>
        {children}
      </Scroll>
    );
  }

  onScroll = async (e: React.UIEvent<HTMLDivElement>) => {
    const {
      threshold
    } = this.props;
    const {
      triggered
    } = this.state;
    if (triggered) return;

    const { currentTarget: element } = e;

    const currentPosition = element.scrollTop + element.clientHeight;
    const maxPosition = element.scrollHeight;

    if (maxPosition <= currentPosition + (typeof threshold === "undefined" ? 10 : threshold)) {
      this.setState({
        triggered: true
      });
      await this.triggerBump();
      this.setState({
        triggered: false
      });
    }
  }

  triggerBump = async () => {
    const { onBump } = this.props;
    await onBump();
  }
}
