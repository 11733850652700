import * as colors from "../colors";
import styled from "styled-components";

const Container = styled.button`
  font-size: 12px;
  min-width: 64px;
  box-sizing: border-box;
  transition:
    background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-weight: 500;
  line-height: 1;
  border-radius: 4px;
  text-transform: uppercase;
  user-select: none;
  padding: 5px 15px;
  color: ${colors.primary};
  border: 1px solid ${colors.primary}88;
  justify-content: center;
  text-decoration: none;
  cursor: pointer;
  outline: none;
  letter-spacing: 0.5px;
  background-color: transparent;

  &:hover {
    border: 1px solid ${colors.primary}aa;
    background-color: ${colors.primary}10;
  }
`;

const Text = styled.div`
  margin-top: 5px;
  margin-bottom: 4px;
`;

export default (props: { onClick?: () => void; onKeyDown?: (e: React.KeyboardEvent) => void; children: any; className?: string; }) => {
  return (
    <Container onClick={props.onClick} onKeyDown={props.onKeyDown} className={props.className}>
      <Text>{props.children}</Text>
    </Container>
  );
};
