import { useEffect, useState } from "react";
import { Channel } from "@etops/logger-api-client";
import channelApi from "../apis/channelApi";

export default (channelId: string) => {
  const [loading, setLoading] = useState(true);
  const [channel, setChannel] = useState<Channel>();

  useEffect(() => {
    if (!loading) return;

    let canceled = false;

    (async () => {
      try {
        const channel = await channelApi.retrieve(channelId);
        if (!canceled) setChannel(channel);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    })();

    return () => {
      canceled = true;
    };
  }, [channelId]);

  return [
    loading,
    channel
  ] as [
      boolean,
      Channel
    ];
};
