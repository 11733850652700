import styled from "styled-components";
import * as colors from "../colors";

const Table = styled.div`
  display: flex;
  flex-direction: column;
  color: ${colors.defaultText};
  overflow: hidden;
`;

export default Table;
