import CenterLayout from "../components/layouts/CenterLayout";
import LoadingIndicator from "../components/LoadingIndicator";

export default () => {
  return (
    <CenterLayout>
      <LoadingIndicator />
    </CenterLayout>
  );
};
