import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import useReady from "./hooks/useReady";
import useAuthentication from "./hooks/useAuthentication";
import LoginScreen from "./screens/LoginScreen";
import HomeScreen from "./screens/HomeScreen";
import LoadingScreen from "./screens/LoadingScreen";

export default () => {
  const ready = useReady();
  const isAuthenticated = useAuthentication();
  if (!ready) return <LoadingScreen />;

  return (
    <BrowserRouter>
      <Routes>
        <Route path="*" element={isAuthenticated ? <HomeScreen /> : <LoginScreen />} />
      </Routes>
    </BrowserRouter>
  );
};
