import styled from "styled-components";

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 30px 30px;
  overflow: hidden;
`;

export default (props) => {
  return (
    <Container>
      {props.children}
    </Container>
  );
};
