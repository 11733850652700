import moment from "moment-timezone";
import BasicTextInput from "./BasicTextInput";
import React, { useEffect, useState } from "react";

interface Props {
  value: Date;
  format?: string;
  onChange?: (date: Date) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}

const defaultFormatSeconds = "YYYY.MM.DD HH:mm:ss";
const defaultFormatMinutes = "YYYY.MM.DD HH:mm";
const defaultFormatDate = "YYYY.MM.DD";

export default (props: Props) => {
  const getFormat = (text: string) => {
    let defaultFormat = defaultFormatDate;

    if (text?.length > 10) defaultFormat = defaultFormatMinutes;
    if (text?.length > 16) defaultFormat = defaultFormatSeconds;

    return props.format || defaultFormat;
  };

  const [text, setText] = useState(props.value ?
    moment(props.value, true).format(getFormat(props.value.toISOString())) :
    ""
  );

  const [value, setValue] = useState(
    props.value ?
      moment(props.value, true).toDate() :
      undefined
  );

  useEffect(() => {
    if (!props.value) setText("");
    else {
      const newValue = moment(props.value, true).toDate();
      setValue(newValue);
      const text = formatText(newValue);
      setText(text);
    }
  }, [props.value]);

  const formatText = (newValue?: Date) => {
    return moment(typeof newValue !== "undefined" ? newValue : value, true).format(getFormat(text));
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const text = e.currentTarget.value;
    setText(text);

    const momentDate = moment(text, getFormat(text), true);
    if (
      props.onChange &&
      momentDate.isValid()
    ) {
      props.onChange(momentDate.toDate());
    } else if (
      props.onChange && (
        typeof text === "undefined" ||
        text === ""
      )
    ) {
      props.onChange(undefined);
    }
  };

  const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    let text = e.currentTarget.value;

    const momentDate = moment(text, getFormat(text));
    if (text && !momentDate.isValid()) {
      text = formatText();
      setText(text);
      const validMomentDate = moment(text, getFormat(text));
      props.onChange(validMomentDate.toDate());
    }
  };

  return (
    <BasicTextInput
      value={text}
      onChange={onChange}
      onKeyDown={props.onKeyDown}
      onBlur={onBlur}
      disabled={props.disabled}
      placeholder="YYYY.MM.DD HH:mm:ss"
    />
  );
};
