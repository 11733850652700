import { useEffect, useState } from "react";
import { Channel } from "@etops/logger-api-client";
import channelApi from "../apis/channelApi";

export default () => {
  const [loading, setLoading] = useState(true);
  const [channels, setChannels] = useState<Channel[]>([]);

  useEffect(() => {
    if (!loading) return;

    let canceled = false;

    (async () => {
      try {
        const channels = await channelApi.list();
        if (!canceled) setChannels(channels);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    })();

    return () => {
      canceled = true;
    };
  }, []);

  return [
    loading,
    channels
  ] as [
      boolean,
      Channel[]
    ];
};
