import Title from "../components/Title";
import React from "react";

export default () => {
  return (
    <div>
      <Title>404</Title>
      Not Found
    </div>
  );
};
