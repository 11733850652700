import * as colors from "../colors";
import styled from "styled-components";

const Container = styled.div`
  width: 75px;
  height: 75px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Spinner = styled.div`
  &, &:before, &:after {
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    position: relative;
    width: 100px;
    height: 100px;
    transform-origin: center center;
    border-radius: 50%;
    border: 4px solid rgba(0,0,0,0);
    border-top-color: ${colors.primary};
    animation: momentum 1s linear infinite;
  }

  &:before {
    content: "";
    position: absolute;
    transform-origin: center center;
    top: 10px;
    left: 10px;
    width: calc(100px * 0.8);
    height: calc(100px * 0.8);
    border-radius: 50%;
    border: 4px solid rgba(0,0,0,0);
    border-top-color: ${colors.primary};
    opacity: 0.7;
    filter: hue-rotate(3eg);
    animation: momentum calc(1s * 2) linear infinite;
  }

  &:after {
    content: "";
    position: absolute;
    top: 20px;
    left: 20px;
    width: calc(100px * 0.6);
    height: calc(100px * 0.6);
    border-radius: 50%;
    transform-origin: center center;
    border: 4px solid rgba(0,0,0,0);
    border-top-color: ${colors.primary};
    opacity: 0.3;
    filter: hue-rotate(6eg);
    animation: momentum 1s linear infinite;
  }

  @keyframes momentum {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(-360deg);
    }
  }
`;

export default () => {
  return (
    <Container>
      <Spinner />
    </Container>
  );
};
