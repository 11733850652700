import { useState, useEffect } from "react";
import appService from "../services/appService";

export default () => {
  const [ready, setReady] = useState(appService.ready);

  const onChange = (isReady: boolean) => {
    setReady(isReady);
  };

  useEffect(() => {
    if (appService.ready) return onChange(true);

    appService.addListener("readyChanged", onChange);

    return () => {
      appService.removeListener("readyChanged", onChange);
    };
  }, []);

  return ready;
};
