import React, { useState } from "react";
import { useParams } from "react-router-dom";
import moment from "moment-timezone";
import { Log } from "@etops/logger-api-client";
import logApi from "../apis/logApi";
import ContentLayout from "../components/layouts/ContentLayout";
import Table from "../components/Table";
import Header from "../components/Header";
import Row from "../components/Row";
import Column from "../components/Column";
import Body from "../components/Body";
import Title from "../components/Title";
import Pagination from "../components/Pagination";
import LoadingScreen from "./LoadingScreen";
import DateInput from "../components/DateInput";
import TextInput from "../components/TextInput";
import styled from "styled-components";
import Button from "../components/Button";
import useChannel from "../hooks/useChannel";

const FilterContainer = styled.div`
  width: 250px;
`;

const LogTitle = styled(Title)`
  margin-top: 20px;
`;

const Separator = styled.div`
  align-items: center;
  border-bottom: 2px solid #ccd0d4;
  padding: 0 20px 20px 20px;
`;

const FilterRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
`;

const MetadataRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
`;

const MetadataTitle = styled.div`
  margin-right: 5px;
  font-weight: 700;
  word-break: keep-all;
`;

const FilterButton = styled(Button)`
  margin-top: 15px;
`;

const DateColumn = styled(Column)`
  width: 75px;
`;

const MessageColumn = styled(Column)`
  width: 35%;
`;

const LevelColumn = styled(Column)`
  width: 50px;
`;

const ChannelColumn = styled(Column)`
  width: 160px;
`;

const MetadataColumn = styled(Column)`
  width: 35%;
`;

export default () => {
  const params = useParams();
  let { channelId } = params;
  if (channelId === "uncategorized") channelId = undefined;

  const [loadingChannel, channel] = useChannel(channelId);
  const [from, setFrom] = useState<Date>();
  const [to, setTo] = useState<Date>();
  const [level, setLevel] = useState<string>();
  const [messageSearch, setMessageSearch] = useState<string>();
  const [logs, setLogs] = useState<Log[]>([]);
  const [loadingLogs, setLoadingLogs] = useState(false);
  const [complete, setComplete] = useState(false);

  const fetchLogs = async () => {
    if (complete || loadingLogs) return;
    try {
      setLoadingLogs(true);

      let beforeDate: Date;
      if (logs.length > 0) {
        beforeDate = logs[logs.length - 1].date;
      }

      const tempLogs = await logApi.list({ channelId, beforeDate, from, to, level });

      setComplete(tempLogs.length === 0);
      setLogs([...logs, ...tempLogs]);
    } catch (err) {
      console.error(err);
    } finally {
      setLoadingLogs(false);
    }
  }

  const filterLogs = async () => {
    if (loadingLogs) return;

    try {
      setLoadingLogs(true);

      const tempLogs = await logApi.list({ channelId, from, to, level, messageSearch });

      setComplete(tempLogs.length === 0);
      setLogs(tempLogs);
    } catch (err) {
      console.error(err);
    } finally {
      setLoadingLogs(false);
    }
  }

  const onFromChange = (date: Date) => setFrom(date);

  const onToChange = (date: Date) => setTo(date);

  const onLevelChange = (e: React.ChangeEvent<HTMLInputElement>) => setLevel(e.currentTarget.value);

  const onMessageSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => setMessageSearch(e.currentTarget.value);

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") filterLogs();
  };

  if (loadingChannel) return <LoadingScreen />;

  return (
    <ContentLayout>
      <FilterContainer>
        <FilterRow>
          From: <DateInput value={from} onChange={onFromChange} onKeyDown={onKeyDown} />
        </FilterRow>

        <FilterRow>
          To: <DateInput value={to} onChange={onToChange} onKeyDown={onKeyDown} />
        </FilterRow>

        <FilterRow>
          Level: <TextInput value={level} onChange={onLevelChange} onKeyDown={onKeyDown} />
        </FilterRow>

        <FilterRow>
          Message: <TextInput value={messageSearch} onChange={onMessageSearchChange} onKeyDown={onKeyDown} />
        </FilterRow>

        <FilterButton onClick={() => filterLogs()}>
          Filter
        </FilterButton>
      </FilterContainer>

      <Separator />

      <LogTitle>Logs</LogTitle>

      <Table>
        <Header>
          <Row>
            <DateColumn>
              Date
            </DateColumn>
            <MessageColumn>
              Message
            </MessageColumn>
            <ChannelColumn>
              Channel
            </ChannelColumn>
            <LevelColumn>
              Level
            </LevelColumn>
            <MetadataColumn>
              Metadata
            </MetadataColumn>
          </Row>
        </Header>

        <Pagination onBump={fetchLogs}>
          <Body>
            {logs.map(log => (
              <Row key={log._id}>
                <DateColumn>
                  {log.date ? moment(log.date).format("YYYY.MM.DD HH:mm:ss") : ""}
                </DateColumn>
                <MessageColumn>
                  {log.message || ""}
                </MessageColumn>
                <ChannelColumn>
                  {channel?.name || ""}
                </ChannelColumn>
                <LevelColumn>
                  {log.level || ""}
                </LevelColumn>
                <MetadataColumn>
                  {log.metadata ?
                    Object.entries(log.metadata).map((data, index) => (
                      <MetadataRow key={`${data[0]}_${index}`}>
                        <MetadataTitle>{data[0]}:</MetadataTitle>
                        <div>{data[1] as string}</div>
                      </MetadataRow>
                    )) :
                    ""
                  }
                </MetadataColumn>
              </Row>
            ))}

            {loadingLogs &&
              <LoadingScreen />
            }
          </Body>
        </Pagination>
      </Table>
    </ContentLayout>
  );
};
